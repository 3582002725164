import React, { useState, useEffect } from "react";
import All from "./All";
import Header from "./Header";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

const client = new ApolloClient({
  uri: "https://api-ap-south-1.hygraph.com/v2/clqxh6vnhss0b01umhohu02l7/master",
  // uri: "https://api-ap-south-1.hygraph.com/v2/clvksir0f000009l9fypp3v17/master",
  cache: new InMemoryCache(),
});

function App() {
  const storedLanguage = localStorage.getItem("currentLanguage");
  const [currentLanguage, setCurrentLanguage] = useState(
    storedLanguage || "en"
  );

  useEffect(() => {
    localStorage.setItem("currentLanguage", currentLanguage);
  }, [currentLanguage]);

  const changeLanguage = (newLanguage) => {
    setCurrentLanguage(newLanguage);
    window.location.reload();
  };

  return (
    <main dir={currentLanguage === "ar" ? "rtl" : "ltr"} lang={currentLanguage}>
      <section className="menu section">
        {currentLanguage === "en" ? (
          <button className="lang-btn" onClick={() => changeLanguage("ar")}>
            اللغة العربية
          </button>
        ) : (
          <button className="lang-btn" onClick={() => changeLanguage("en")}>
            English
          </button>
        )}
        <ApolloProvider client={client}>
          <Header propValue={currentLanguage} />
          <All propValue={currentLanguage} />
        </ApolloProvider>
      </section>
      <ul className="social-icons">
        <li>
          {" "}
          <a href="https://www.instagram.com/falamankicafekw/" target="_blank">
            <img src="../images/insta.png" />
          </a>
        </li>
        <li>
          {" "}
          <a href="https://www.tiktok.com/@falamankicaffekw" target="_blank">
            <img src="../images/tiktok.png" />
          </a>
        </li>
      </ul>
      <p className="text-center m-2">© 2024 Falamanki</p>
    </main>
  );
}

export default App;
