import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-modal";

const Categories = ({ categories, lang }) => {
  // Dish Modal
  const [selectedDish, setSelectedDish] = useState(null);

  const openModal = (dish) => {
    setSelectedDish(dish);
  };
  const closeModal = () => {
    setSelectedDish(null);
  };

  return (
    <>
      {/* ===== details modal ===== */}
      {selectedDish && (
        <Modal
          isOpen={!!selectedDish}
          onRequestClose={closeModal}
          contentLabel="Dish Details Modal"
        >
          <img
            src={selectedDish?.image?.url}
            alt={selectedDish?.dishName}
            className="card-img"
          />
          <div
            style={{
              direction: lang === "ar" ? "rtl" : "ltr",
            }}
          >
            <h5 className="pt-4 pb-2 px-3 m-0">{selectedDish?.dishName}</h5>
            <p className="px-3">{selectedDish?.dishDetails}</p>
            <p className="px-3 price">{selectedDish?.dishPrice} KWD</p>
            <button className="close-btn" onClick={closeModal}>
              +
            </button>
          </div>
        </Modal>
      )}
      {/* ===== list ===== */}
      {categories?.map((model, i) => (
        <div key={i}>
          {model?.createDishCategoryDishes?.map((category, i) => (
            <div
              key={i}
              className="section-center overflow-hidden"
              id={category?.dishCategoryTitle?.replaceAll(
                /[^a-zA-Z0-9\u0600-\u06FF]/g,
                "-"
              )}
            >
              <h4
                className={`section-title ${category?.dishCategoryTitle.replaceAll(
                  /[^a-zA-Z0-9\u0600-\u06FF]/g,
                  "-"
                )}`}
              >
                {category?.dishCategoryTitle}
              </h4>
              <Row>
                {Array.isArray(category?.addDishes) &&
                  category?.addDishes.map((dish, i) => (
                    <Col xs={12} md={6} key={i} className="menu-list-item px-0">
                      <Row
                        className="d-flex flex-nowrap gap-2 border-top align-items-top py-3 px-2 mx-3"
                        onClick={() => openModal(dish)}
                      >
                        <Col className="p-0" xs="auto">
                          <img
                            loading="lazy"
                            src={dish?.image?.url}
                            alt={dish?.dishName}
                            className="photo"
                          />
                        </Col>
                        <Col>
                          <h4 className="mb-1 title">{dish?.dishName}</h4>
                          <p className="item-text item-desc truncate">
                            {dish?.dishDetails}
                          </p>
                          {dish?.dishPrice > 0 && (
                            <h6 className="price">{dish?.dishPrice} KWD</h6>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  ))}
              </Row>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

export default Categories;
