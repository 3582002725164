import React, { useState, useEffect, useRef } from "react";
import { useQuery, gql } from "@apollo/client";

const YourComponent = (props) => {
  // console.log('test', props.propValue);

  const graphqlQuery = gql`
    query MyQuery($id: ID!) {
      values: falamankiModel(where: { id: $id }  locales: ${props.propValue} ) {
        id
        logo {
          url
        }
        video {
          url
        }
        webisteTitle
      }
    }
  `;

  const videoRef = useRef(null);
  const buttonRef = useRef(null);

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Error playing the video:", error);
      });
    }
  };

  useEffect(() => {
    // Trigger click on the button when the component mounts
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  }, []); // Empty dependency array ensures this effect runs only once, on mount

  const { loading, error, data } = useQuery(graphqlQuery, {
    variables: { id: "clqxh99wy4cy10bpj5yx9g53p" },
  });

  if (loading) return <p>Loading...</p>;

  if (error) return <p>Error: {error.message}</p>;

  const websiteVideo = data?.values?.video.url || "N/A";
  const websiteLogo = data?.values?.logo.url || "N/A";
  const websiteTitle = data?.values?.webisteTitle || "N/A";

  return (
    <div>
      <div className="menu-card">
        <video
          ref={videoRef}
          className="bg-video"
          playsInline
          autoPlay
          muted
          loop
        >
          <source src={websiteVideo} type="video/mp4" />
        </video>
        <button
          ref={buttonRef}
          onClick={handlePlay}
          style={{ display: "none" }}
        >
          Click me
        </button>
      </div>
      <div className="menu-detail card-body text-center pb-3">
        <div className="pro-img">
          <img src={websiteLogo} alt="user" />
        </div>
        <h3 className="m-b-0">{websiteTitle}</h3>

        {props.propValue === "en" ? <h6>~ MENU ~</h6> : <h6>~ قائمة طعام ~</h6>}
      </div>
    </div>
  );
};

export default YourComponent;
