import React, { useState, useEffect, useRef } from "react";
import { ScrollContainer } from "react-indiana-drag-scroll";

const Menu = ({ categories, lang }) => {
  let previousSectionScroll = 0;
  // active tab
  const [translateX, setTranslateX] = useState(0);
  const [activeSection, setActiveSection] = useState(null);
  const scrollContainerRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll(".section-center");
      let currentSection = "";

      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= 10 && rect.bottom > 10) {
          currentSection = section.id;
        }
      });
      if (currentSection) {
        setActiveSection(currentSection);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // Scroll to active section when it changes
    if (activeSection) {
      const button = document.querySelector(`#${"btn-" + activeSection}`);
      // Add a delay before scrollIntoView
      const timeoutId = setTimeout(() => {
        button.scrollIntoView({
          inline: "start",
          block: "start",
          behavior: "smooth",
        });
      }, 400); // 400ms delay

      // Clear the timeout when activeSection changes or component unmounts
      return () => clearTimeout(timeoutId);
    }
  }, [activeSection]);

  return (
    <nav className="sticky-category-nav">
      <div className="categories-slide border-bottom-x">
        <ScrollContainer ref={scrollContainerRef}>
          {categories?.map((model, i) => (
            <React.Fragment key={i}>
              <div
                style={{
                  transform: `translateX(${
                    lang === "en" ? -translateX : -translateX
                  }px)`,
                }}
                className="btn-container"
              >
                {model?.createDishCategoryDishes?.map((category, i) => (
                  <a
                    key={i}
                    href={`#${category?.dishCategoryTitle.replaceAll(
                      /[^a-zA-Z0-9\u0600-\u06FF]/g,
                      "-"
                    )}`}
                    type="button"
                    id={
                      "btn-" +
                      category?.dishCategoryTitle?.replaceAll(
                        /[^a-zA-Z0-9\u0600-\u06FF]/g,
                        "-"
                      )
                    }
                    className={`filter-btn ${
                      activeSection ===
                      category?.dishCategoryTitle?.replaceAll(
                        /[^a-zA-Z0-9\u0600-\u06FF]/g,
                        "-"
                      )
                        ? "active"
                        : ""
                    }`}
                  >
                    {category?.dishCategoryTitle}
                  </a>
                ))}
              </div>
            </React.Fragment>
          ))}
        </ScrollContainer>
      </div>
    </nav>
  );
};

export default Menu;
