import React from "react";
import { useQuery, gql } from "@apollo/client";
import "react-indiana-drag-scroll/dist/style.css";
import Categories from "./Categories";
import Menu from "./Menu";

const YourComponent = ({ propValue }) => {
  const graphqlQuery = gql`
    query MyQuery {
      falamankiModels {
        createDishCategoryDishes(first: 200, locales: ${propValue}) {
          ... on MainDishCategory {
            dishCategoryTitle
            addDishes(first: 200) {
              ... on Menu {
                id
                dishPrice
                dishName
                dishDetails
                image(locales: en) {
                  url
                }
              }
            }
          }
        }
      }
    }`;

  // Data calling
  const { loading, error, data } = useQuery(graphqlQuery);
  if (loading)
    return (
      <div id="preloader">
        <div className="loader"></div>
      </div>
    );
  if (error) return <p>Error: {error.message}</p>;
  const falamankiModels = data?.falamankiModels || [];

  return (
    <div>
      <Menu categories={falamankiModels} lang={propValue} />
      <Categories categories={falamankiModels} lang={propValue} />
    </div>
  );
};

export default YourComponent;
